<template>
  <v-container>
    <v-row>
      <v-col cols="12"
             md="11">
        <h1>Monthly Contact by Messages</h1>
      </v-col>
      <v-col cols="12"
             md="1">
        <v-menu
          bottom
          left>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list rounded>
            <v-subheader>Menu</v-subheader>
            <v-list-item-group>
              <v-list-item
                v-for="(item, i) in submenu"
                :key="i"
                dense
                :to="{name: item.link}">
                <v-list-item-icon>
                  <v-icon v-text="item.icon" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text" />
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <find-condition :monthInfo="today" />
    <report-list :reportData="reportDataList.reports"
      :loadReport="reportLoading" />
    <v-btn
      v-show="reportExport"
      class="mt-5"
      color="primary"
      outlined
      :loading="reportExportLoading"
      :disabled="reportExportLoading"
      @click="exportsData">
      <template v-slot:loader>
        <span class="custom-loader">
          <v-icon light>mdi-cached</v-icon>
        </span>
      </template>
      <v-icon class="mr-2">mdi-file-export-outline</v-icon>
      Export
    </v-btn>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MonthlyReportContactByMessage',
  components: {
    ReportList: () => import('@/components/Reports/contactByMessage.vue'),
    FindCondition: () => import('@/components/Reports/monthCondition.vue')
  },
  data() {
    return {
      submenu: [
        { text: 'Monthly', icon: 'mdi-calendar-month-outline', link: 'contactByMessagesMonthly' },
        { text: 'Daily', icon: 'mdi-calendar-outline', link: 'contactByMessagesDaily' },
      ],
      today: {
        year: 0,
        month: 0,
        tel_type: { keys: 'ALL', value: 'A' }
      },
    };
  },
  computed: {
    ...mapGetters({
      reportDataList: 'reports/GET_REPORTS',
      reportLoading: 'reports/GET_REPORT_LOADING',
      reportExport: 'reports/GET_REPORT_EXPORT',
      reportExportLoading: 'reports/GET_REPORT_EXPORT_LOADING'
    }),

  },
  beforeDestroy() {
    // eslint-disable-next-line no-undef
    eventBus.$off('findData');
  },
  async created() {
    // const currentDate = new Date();
    // this.today.year = currentDate.getFullYear();
    // this.today.month = currentDate.getMonth() + 1;

    await this.$store.dispatch('reports/ClearDataReport',[]);

    // eslint-disable-next-line no-undef
    eventBus.$on('findData', data => this.findData(data));
  },

  methods: {
    async findData() {
      // this.loadData = true;
      // this.$store.commit('reports/REPORT_LOADING', true)

      await this.$store.dispatch('reports/ClearDataReport',[]);
      await this.$store.dispatch('reports/GetReportContactByMessageMonthly', this.today)
        // .then(() => {
        //   this.exportStatus = true;
        // });
    },

    async exportsData() {
      // this.exportLoading = true;
      await this.$store.dispatch('reports/exportReportContactByMessageMonthly', this.today)
        // .then(() => {
        //   this.exportLoading = false;
        // })
    }
  }
};
</script>
